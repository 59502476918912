#background{
  min-height: 100vh;
  min-width: 100vw;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
.d-flex::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.d-flex {
  scrollbar-width: none;
}
